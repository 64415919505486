import React, { useState, useContext, createContext, useMemo } from "react";
import { rem, space, fontSize } from "../design-system";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Button from "../components/Button";
import {
  Form,
  FormHeader,
  FormSection,
  FormField,
  Input,
  InputRow,
  FormFooter,
  TextArea,
} from "../components/Form";
import P from "../components/P";
import Link from "../components/Link";
import styled from "styled-components";

const defaultState = {
  values: {
    fullName: "",
    phone: "",
    email: "",
    message: "",
  },
};

const FormStateContext = createContext([defaultState, () => {}]);

function ContactFormInput({ type = "text", id, ...rest }) {
  const [state, setState] = useContext(FormStateContext);

  return (
    <Input
      {...rest}
      type={type}
      id={id}
      name={id}
      value={state.values[id]}
      onChange={({ target }) => {
        setState({
          ...state,
          values: {
            ...state.values,
            [id]: target.value,
          },
        });
      }}
    />
  );
}

function ContactFormTextArea({ type = "text", id, ...rest }) {
  const [state, setState] = useContext(FormStateContext);

  return (
    <TextArea
      {...rest}
      id={id}
      name={id}
      value={state.values[id]}
      onChange={({ target }) => {
        setState({
          ...state,
          values: {
            ...state.values,
            [id]: target.value,
          },
        });
      }}
    />
  );
}

const ContactInfoTitle = styled.h1`
  font-size: ${rem(fontSize(400))};
  margin-bottom: ${rem(space(100))};
`;

function ContactPage() {
  const [state, setState] = useState(defaultState);

  return (
    <Layout>
      <SEO title="Contact Us" />
      <FormStateContext.Provider
        value={useMemo(() => [state, setState], [state, setState])}
      >
        <Form
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          name="Contact"
          method="POST"
          action="/contact-submitted"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="Contact" />
          <FormHeader>
            <h1>Contact Us</h1>
          </FormHeader>
          <FormSection
            description={
              <>
                <ContactInfoTitle>Burch Rentals</ContactInfoTitle>
                <P>
                  <Link href="tel:4178278852">417-827-8852</Link>
                  <br />
                  <Link href="mailto:burchrentals@yahoo.com">
                    burchrentals@yahoo.com
                  </Link>
                </P>
              </>
            }
          >
            <FormField label="Full Name*">
              <ContactFormInput id="fullName" required />
            </FormField>
            <InputRow>
              <FormField label="Phone Number">
                <ContactFormInput id="phone" type="tel" />
              </FormField>
              <FormField label="Email">
                <ContactFormInput id="email" type="email" />
              </FormField>
            </InputRow>
            <FormField label="Message*">
              <ContactFormTextArea id="message" rows="10" required />
            </FormField>
          </FormSection>
          <FormFooter>
            <Button>Send Message</Button>
          </FormFooter>
        </Form>
      </FormStateContext.Provider>
    </Layout>
  );
}

export default ContactPage;
